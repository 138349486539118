import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import SubPageNav from './SubPageNav';

const ComplaintsProcessNav = ({ activePath }) => {
	const linksArr = [
		{
			text: <FormattedMessage id='complaintsProcess.page01' />,
			path: '/licensing-compliance/complaints-process/'
		},
		{
			text: <FormattedMessage id='complaintsProcess.page02' />,
			path: '/licensing-compliance/how-to-file-a-complaint/'
		},
		{
			text: <FormattedMessage id='complaintsProcess.page03' />,
			path: '/licensing-compliance/possible-courses-of-action/'
		}
	];

	const activeArr = linksArr.map((link) => {
		if (link.path === activePath) link['active'] = true;
		return link;
	});

	return <SubPageNav listStyle='unordered' links={activeArr} />;
};

export default ComplaintsProcessNav;
