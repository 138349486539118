import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import ComplaintsProcessNav from '../../components/SubPageNav/ComplaintsProcessNav';
import SubPageBottomNav from '../../components/SubPageBottomNav/SubPageBottomNav';

const CoursesOfActionPage = () => {
	const intl = useIntl();
	return (
		<Layout header={<FormattedMessage id='complaintsProcess.page03' />}>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.complaintsProcessDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'complaintsProcess.page03' })}
			/>
			<Container>
				<Row>
					<Col lg={3} md={3}>
						<ComplaintsProcessNav activePath='/licensing-compliance/possible-courses-of-action/' />
					</Col>

					<Col>
						<Row className='pageRow'>
							<Col>
								<p style={{ marginTop: `0` }}>
									<FormattedMessage id='complaintsProcess.content05A' />
								</p>
								<p>
									<FormattedMessage id='complaintsProcess.content05B' />
								</p>
								<p>
									<FormattedMessage id='complaintsProcess.content05C' />
								</p>
								<ol className='largeNums'>
									<li>
										<div>
											<b>
												<FormattedMessage id='complaintsProcess.list05L_B' />
											</b>
											<br />
											<FormattedMessage id='complaintsProcess.list05Li' />
											<br />
											<br />
											<FormattedMessage id='complaintsProcess.list05Lii' />
										</div>
									</li>
									<li>
										<div>
											<b>
												<FormattedMessage id='complaintsProcess.list05A_B' />
											</b>
											<br />
											<FormattedMessage id='complaintsProcess.list05A' />
										</div>
									</li>
									<li>
										<div>
											<b>
												<FormattedMessage id='complaintsProcess.list05B_B' />
											</b>
											<br />
											<FormattedMessage id='complaintsProcess.list05B' />{' '}
											<Link to='/licensing-compliance/regulatory-activites-enforcement/'>
												<FormattedMessage id='complaintsProcess.list05Bi' />
											</Link>
											:
											<ul>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05C_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05C' />
												</li>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05D_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05D' />
												</li>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05H_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05H' />
												</li>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05E_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05E' />
												</li>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05F_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05F' />
												</li>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05G_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05G' />
												</li>
												<li>
													<b>
														<FormattedMessage id='complaintsProcess.list05K_B' />
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05K' />{' '}
													<Link to='/about/discipline-and-appeals-committees/'>
														<FormattedMessage id='complaintsProcess.list05Ki' />
													</Link>{' '}
													<FormattedMessage id='complaintsProcess.list05Kii' />
												</li>
												<li>
													<b>
														<Link to='/builder-vendor/complaints-compliance-enforcement/administrative-penalty/'>
															<FormattedMessage id='complaintsProcess.list05O_B' />
														</Link>
													</b>
													<br />
													<FormattedMessage id='complaintsProcess.list05O' />
												</li>
											</ul>
										</div>
									</li>

									<li>
										<div>
											<b>
												<FormattedMessage id='complaintsProcess.list05M_B' />
											</b>
											<br />
											<FormattedMessage id='complaintsProcess.list05Mi' />{' '}
											<Link to='/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/'>
												<FormattedMessage id='complaintsProcess.list05Mii' />
											</Link>{' '}
											<FormattedMessage id='complaintsProcess.list05Miii' />
										</div>
									</li>

									<li>
										<div>
											<b>
												<FormattedMessage id='complaintsProcess.list05I_B' />
											</b>
											<br />
											<FormattedMessage id='complaintsProcess.list05Ni' />{' '}
											<a
												href={intl.formatMessage({
													id: 'linkNames.nhclaLink'
												})}
											>
												<FormattedMessage id='linkNames.nhclaAlt' />
											</a>
											<FormattedMessage id='complaintsProcess.list05Nii' />{' '}
											<a
												href={intl.formatMessage({
													id: 'linkNames.onhwpaLink'
												})}
											>
												<FormattedMessage id='linkNames.onhwpa' />
											</a>{' '}
											<FormattedMessage id='complaintsProcess.list05Niii' />{' '}
											<a
												href={intl.formatMessage({
													id: 'linkNames.poaLink'
												})}
											>
												<FormattedMessage id='linkNames.poa' />
											</a>
											. <FormattedMessage id='complaintsProcess.list05Niv' />
										</div>
									</li>

									<li>
										<div>
											<b>
												<FormattedMessage id='daCommittee.headerI' />
											</b>
											<br />
											<FormattedMessage id='daCommittee.contentGiv' />
											<br />
											<br />
											<FormattedMessage id='daCommittee.contentGii' />
											<ul>
												<li>
													<FormattedMessage id='daCommittee.list05a' />
												</li>
												<li>
													<FormattedMessage id='daCommittee.list05b' />
												</li>
												<li>
													<FormattedMessage id='daCommittee.list05c' />
												</li>
												<li>
													<FormattedMessage id='daCommittee.list05d' />
												</li>
												<li>
													<FormattedMessage id='daCommittee.list05e' />
												</li>
											</ul>
											<FormattedMessage id='daCommittee.contentGv' />
										</div>
									</li>
								</ol>
								<p>
									<span style={{ fontStyle: `italic` }}>
										<FormattedMessage id='complaintsProcess.list050i' />
									</span>
								</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col>
						<SubPageBottomNav
							prevLink={{
								text: <FormattedMessage id='complaintsProcess.page02' />,
								path: '/licensing-compliance/how-to-file-a-complaint/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default CoursesOfActionPage;
